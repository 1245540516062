exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-comics-angry-shark-tsx": () => import("./../../../src/pages/comics/angry-shark.tsx" /* webpackChunkName: "component---src-pages-comics-angry-shark-tsx" */),
  "component---src-pages-comics-anorexia-tsx": () => import("./../../../src/pages/comics/anorexia.tsx" /* webpackChunkName: "component---src-pages-comics-anorexia-tsx" */),
  "component---src-pages-comics-bananimals-tsx": () => import("./../../../src/pages/comics/bananimals.tsx" /* webpackChunkName: "component---src-pages-comics-bananimals-tsx" */),
  "component---src-pages-comics-bucket-tsx": () => import("./../../../src/pages/comics/bucket.tsx" /* webpackChunkName: "component---src-pages-comics-bucket-tsx" */),
  "component---src-pages-comics-burger-tsx": () => import("./../../../src/pages/comics/burger.tsx" /* webpackChunkName: "component---src-pages-comics-burger-tsx" */),
  "component---src-pages-comics-carnivorous-unicorn-returns-tsx": () => import("./../../../src/pages/comics/carnivorous-unicorn-returns.tsx" /* webpackChunkName: "component---src-pages-comics-carnivorous-unicorn-returns-tsx" */),
  "component---src-pages-comics-carnivorous-unicorn-tsx": () => import("./../../../src/pages/comics/carnivorous-unicorn.tsx" /* webpackChunkName: "component---src-pages-comics-carnivorous-unicorn-tsx" */),
  "component---src-pages-comics-chaps-tsx": () => import("./../../../src/pages/comics/chaps.tsx" /* webpackChunkName: "component---src-pages-comics-chaps-tsx" */),
  "component---src-pages-comics-chicken-from-the-future-tsx": () => import("./../../../src/pages/comics/chicken-from-the-future.tsx" /* webpackChunkName: "component---src-pages-comics-chicken-from-the-future-tsx" */),
  "component---src-pages-comics-chickin-makeover-tsx": () => import("./../../../src/pages/comics/chickin-makeover.tsx" /* webpackChunkName: "component---src-pages-comics-chickin-makeover-tsx" */),
  "component---src-pages-comics-crocodiles-tsx": () => import("./../../../src/pages/comics/crocodiles.tsx" /* webpackChunkName: "component---src-pages-comics-crocodiles-tsx" */),
  "component---src-pages-comics-dr-cow-tsx": () => import("./../../../src/pages/comics/dr-cow.tsx" /* webpackChunkName: "component---src-pages-comics-dr-cow-tsx" */),
  "component---src-pages-comics-elevator-tsx": () => import("./../../../src/pages/comics/elevator.tsx" /* webpackChunkName: "component---src-pages-comics-elevator-tsx" */),
  "component---src-pages-comics-fish-vs-fish-tsx": () => import("./../../../src/pages/comics/fish-vs-fish.tsx" /* webpackChunkName: "component---src-pages-comics-fish-vs-fish-tsx" */),
  "component---src-pages-comics-frog-tsx": () => import("./../../../src/pages/comics/frog.tsx" /* webpackChunkName: "component---src-pages-comics-frog-tsx" */),
  "component---src-pages-comics-giant-turtle-tsx": () => import("./../../../src/pages/comics/giant-turtle.tsx" /* webpackChunkName: "component---src-pages-comics-giant-turtle-tsx" */),
  "component---src-pages-comics-horse-breakfast-tsx": () => import("./../../../src/pages/comics/horse-breakfast.tsx" /* webpackChunkName: "component---src-pages-comics-horse-breakfast-tsx" */),
  "component---src-pages-comics-horse-comics-tsx": () => import("./../../../src/pages/comics/horse-comics.tsx" /* webpackChunkName: "component---src-pages-comics-horse-comics-tsx" */),
  "component---src-pages-comics-hypo-hippo-tsx": () => import("./../../../src/pages/comics/hypo-hippo.tsx" /* webpackChunkName: "component---src-pages-comics-hypo-hippo-tsx" */),
  "component---src-pages-comics-important-race-tsx": () => import("./../../../src/pages/comics/important-race.tsx" /* webpackChunkName: "component---src-pages-comics-important-race-tsx" */),
  "component---src-pages-comics-index-tsx": () => import("./../../../src/pages/comics/index.tsx" /* webpackChunkName: "component---src-pages-comics-index-tsx" */),
  "component---src-pages-comics-ipod-tsx": () => import("./../../../src/pages/comics/ipod.tsx" /* webpackChunkName: "component---src-pages-comics-ipod-tsx" */),
  "component---src-pages-comics-luna-tsx": () => import("./../../../src/pages/comics/luna.tsx" /* webpackChunkName: "component---src-pages-comics-luna-tsx" */),
  "component---src-pages-comics-mammal-and-bird-tsx": () => import("./../../../src/pages/comics/mammal-and-bird.tsx" /* webpackChunkName: "component---src-pages-comics-mammal-and-bird-tsx" */),
  "component---src-pages-comics-mammal-and-reptile-tsx": () => import("./../../../src/pages/comics/mammal-and-reptile.tsx" /* webpackChunkName: "component---src-pages-comics-mammal-and-reptile-tsx" */),
  "component---src-pages-comics-musical-tsx": () => import("./../../../src/pages/comics/musical.tsx" /* webpackChunkName: "component---src-pages-comics-musical-tsx" */),
  "component---src-pages-comics-my-best-friend-tsx": () => import("./../../../src/pages/comics/my-best-friend.tsx" /* webpackChunkName: "component---src-pages-comics-my-best-friend-tsx" */),
  "component---src-pages-comics-office-giraffe-tsx": () => import("./../../../src/pages/comics/office-giraffe.tsx" /* webpackChunkName: "component---src-pages-comics-office-giraffe-tsx" */),
  "component---src-pages-comics-poop-ice-cream-tsx": () => import("./../../../src/pages/comics/poop-ice-cream.tsx" /* webpackChunkName: "component---src-pages-comics-poop-ice-cream-tsx" */),
  "component---src-pages-comics-power-horse-tsx": () => import("./../../../src/pages/comics/power-horse.tsx" /* webpackChunkName: "component---src-pages-comics-power-horse-tsx" */),
  "component---src-pages-comics-space-shark-tsx": () => import("./../../../src/pages/comics/space-shark.tsx" /* webpackChunkName: "component---src-pages-comics-space-shark-tsx" */),
  "component---src-pages-comics-spaceman-explorer-tsx": () => import("./../../../src/pages/comics/spaceman-explorer.tsx" /* webpackChunkName: "component---src-pages-comics-spaceman-explorer-tsx" */),
  "component---src-pages-comics-the-race-tsx": () => import("./../../../src/pages/comics/the-race.tsx" /* webpackChunkName: "component---src-pages-comics-the-race-tsx" */),
  "component---src-pages-comics-the-zoo-tsx": () => import("./../../../src/pages/comics/the-zoo.tsx" /* webpackChunkName: "component---src-pages-comics-the-zoo-tsx" */),
  "component---src-pages-comics-zoom-vs-fax-tsx": () => import("./../../../src/pages/comics/zoom-vs-fax.tsx" /* webpackChunkName: "component---src-pages-comics-zoom-vs-fax-tsx" */),
  "component---src-pages-illustrations-1-tsx": () => import("./../../../src/pages/illustrations/1.tsx" /* webpackChunkName: "component---src-pages-illustrations-1-tsx" */),
  "component---src-pages-illustrations-2-tsx": () => import("./../../../src/pages/illustrations/2.tsx" /* webpackChunkName: "component---src-pages-illustrations-2-tsx" */),
  "component---src-pages-illustrations-3-tsx": () => import("./../../../src/pages/illustrations/3.tsx" /* webpackChunkName: "component---src-pages-illustrations-3-tsx" */),
  "component---src-pages-illustrations-4-tsx": () => import("./../../../src/pages/illustrations/4.tsx" /* webpackChunkName: "component---src-pages-illustrations-4-tsx" */),
  "component---src-pages-illustrations-5-tsx": () => import("./../../../src/pages/illustrations/5.tsx" /* webpackChunkName: "component---src-pages-illustrations-5-tsx" */),
  "component---src-pages-illustrations-6-tsx": () => import("./../../../src/pages/illustrations/6.tsx" /* webpackChunkName: "component---src-pages-illustrations-6-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-stories-angry-shark-tsx": () => import("./../../../src/pages/stories/angry-shark.tsx" /* webpackChunkName: "component---src-pages-stories-angry-shark-tsx" */),
  "component---src-pages-stories-anorexia-tsx": () => import("./../../../src/pages/stories/anorexia.tsx" /* webpackChunkName: "component---src-pages-stories-anorexia-tsx" */),
  "component---src-pages-stories-bananimals-tsx": () => import("./../../../src/pages/stories/bananimals.tsx" /* webpackChunkName: "component---src-pages-stories-bananimals-tsx" */),
  "component---src-pages-stories-bucket-tsx": () => import("./../../../src/pages/stories/bucket.tsx" /* webpackChunkName: "component---src-pages-stories-bucket-tsx" */),
  "component---src-pages-stories-burger-tsx": () => import("./../../../src/pages/stories/burger.tsx" /* webpackChunkName: "component---src-pages-stories-burger-tsx" */),
  "component---src-pages-stories-carnivorous-unicorn-returns-tsx": () => import("./../../../src/pages/stories/carnivorous-unicorn-returns.tsx" /* webpackChunkName: "component---src-pages-stories-carnivorous-unicorn-returns-tsx" */),
  "component---src-pages-stories-carnivorous-unicorn-tsx": () => import("./../../../src/pages/stories/carnivorous-unicorn.tsx" /* webpackChunkName: "component---src-pages-stories-carnivorous-unicorn-tsx" */),
  "component---src-pages-stories-chaps-tsx": () => import("./../../../src/pages/stories/chaps.tsx" /* webpackChunkName: "component---src-pages-stories-chaps-tsx" */),
  "component---src-pages-stories-chicken-from-the-future-tsx": () => import("./../../../src/pages/stories/chicken-from-the-future.tsx" /* webpackChunkName: "component---src-pages-stories-chicken-from-the-future-tsx" */),
  "component---src-pages-stories-chickin-makeover-tsx": () => import("./../../../src/pages/stories/chickin-makeover.tsx" /* webpackChunkName: "component---src-pages-stories-chickin-makeover-tsx" */),
  "component---src-pages-stories-crocodiles-tsx": () => import("./../../../src/pages/stories/crocodiles.tsx" /* webpackChunkName: "component---src-pages-stories-crocodiles-tsx" */),
  "component---src-pages-stories-dr-cow-tsx": () => import("./../../../src/pages/stories/dr-cow.tsx" /* webpackChunkName: "component---src-pages-stories-dr-cow-tsx" */),
  "component---src-pages-stories-elevator-tsx": () => import("./../../../src/pages/stories/elevator.tsx" /* webpackChunkName: "component---src-pages-stories-elevator-tsx" */),
  "component---src-pages-stories-fish-vs-fish-tsx": () => import("./../../../src/pages/stories/fish-vs-fish.tsx" /* webpackChunkName: "component---src-pages-stories-fish-vs-fish-tsx" */),
  "component---src-pages-stories-frog-tsx": () => import("./../../../src/pages/stories/frog.tsx" /* webpackChunkName: "component---src-pages-stories-frog-tsx" */),
  "component---src-pages-stories-giant-turtle-tsx": () => import("./../../../src/pages/stories/giant-turtle.tsx" /* webpackChunkName: "component---src-pages-stories-giant-turtle-tsx" */),
  "component---src-pages-stories-horse-breakfast-tsx": () => import("./../../../src/pages/stories/horse-breakfast.tsx" /* webpackChunkName: "component---src-pages-stories-horse-breakfast-tsx" */),
  "component---src-pages-stories-horse-comics-tsx": () => import("./../../../src/pages/stories/horse-comics.tsx" /* webpackChunkName: "component---src-pages-stories-horse-comics-tsx" */),
  "component---src-pages-stories-hypo-hippo-tsx": () => import("./../../../src/pages/stories/hypo-hippo.tsx" /* webpackChunkName: "component---src-pages-stories-hypo-hippo-tsx" */),
  "component---src-pages-stories-important-race-tsx": () => import("./../../../src/pages/stories/important-race.tsx" /* webpackChunkName: "component---src-pages-stories-important-race-tsx" */),
  "component---src-pages-stories-index-tsx": () => import("./../../../src/pages/stories/index.tsx" /* webpackChunkName: "component---src-pages-stories-index-tsx" */),
  "component---src-pages-stories-ipod-tsx": () => import("./../../../src/pages/stories/ipod.tsx" /* webpackChunkName: "component---src-pages-stories-ipod-tsx" */),
  "component---src-pages-stories-luna-tsx": () => import("./../../../src/pages/stories/luna.tsx" /* webpackChunkName: "component---src-pages-stories-luna-tsx" */),
  "component---src-pages-stories-mammal-and-bird-tsx": () => import("./../../../src/pages/stories/mammal-and-bird.tsx" /* webpackChunkName: "component---src-pages-stories-mammal-and-bird-tsx" */),
  "component---src-pages-stories-mammal-and-reptile-tsx": () => import("./../../../src/pages/stories/mammal-and-reptile.tsx" /* webpackChunkName: "component---src-pages-stories-mammal-and-reptile-tsx" */),
  "component---src-pages-stories-musical-tsx": () => import("./../../../src/pages/stories/musical.tsx" /* webpackChunkName: "component---src-pages-stories-musical-tsx" */),
  "component---src-pages-stories-my-best-friend-tsx": () => import("./../../../src/pages/stories/my-best-friend.tsx" /* webpackChunkName: "component---src-pages-stories-my-best-friend-tsx" */),
  "component---src-pages-stories-office-giraffe-tsx": () => import("./../../../src/pages/stories/office-giraffe.tsx" /* webpackChunkName: "component---src-pages-stories-office-giraffe-tsx" */),
  "component---src-pages-stories-poop-ice-cream-tsx": () => import("./../../../src/pages/stories/poop-ice-cream.tsx" /* webpackChunkName: "component---src-pages-stories-poop-ice-cream-tsx" */),
  "component---src-pages-stories-power-horse-tsx": () => import("./../../../src/pages/stories/power-horse.tsx" /* webpackChunkName: "component---src-pages-stories-power-horse-tsx" */),
  "component---src-pages-stories-space-shark-tsx": () => import("./../../../src/pages/stories/space-shark.tsx" /* webpackChunkName: "component---src-pages-stories-space-shark-tsx" */),
  "component---src-pages-stories-spaceman-explorer-tsx": () => import("./../../../src/pages/stories/spaceman-explorer.tsx" /* webpackChunkName: "component---src-pages-stories-spaceman-explorer-tsx" */),
  "component---src-pages-stories-the-race-tsx": () => import("./../../../src/pages/stories/the-race.tsx" /* webpackChunkName: "component---src-pages-stories-the-race-tsx" */),
  "component---src-pages-stories-the-zoo-tsx": () => import("./../../../src/pages/stories/the-zoo.tsx" /* webpackChunkName: "component---src-pages-stories-the-zoo-tsx" */),
  "component---src-pages-stories-zoom-vs-fax-tsx": () => import("./../../../src/pages/stories/zoom-vs-fax.tsx" /* webpackChunkName: "component---src-pages-stories-zoom-vs-fax-tsx" */)
}

